<template>
  <div id="app">
    <div
      class="h-100 w-full flex items-center justify-center bg-teal-lightest font-sans"
    >
      <div class="bg-white rounded shadow p-6 m-4 w-full lg:w-3/4">
        <div class="mb-4">
          <h1 class="text-grey-darkest">Todo List</h1>
          <div class="flex mt-4">
            <input
              v-model="value"
              class="shadow appearance-none border rounded w-full py-2 px-3 mr-4 text-grey-darker"
              placeholder="Add Todo"
            />
            <button
              @click="createData"
              class="flex-no-shrink p-2 border-2 rounded text-teal border-teal hover:text-white hover:bg-teal"
            >
              Add
            </button>
          </div>
        </div>
        <div>
          <div
            v-for="todo in todos"
            :key="todo._id"
            class="flex mb-4 items-center"
          >
            <p
              :class="
                `w-full ${
                  todo.isDone ? 'line-through text-green' : 'text-grey-darkest'
                }`
              "
            >
              {{ todo.name }}
            </p>
            <button
              v-if="!todo.isDone"
              @click="setDone(todo._id, todo.isDone)"
              class="flex-no-shrink p-2 ml-4 mr-2 border-2 rounded hover:text-white text-green border-green hover:bg-green"
            >
              Done
            </button>
            <button
              v-else
              @click="setDone(todo._id, todo.isDone)"
              class="flex-no-shrink p-2 ml-4 mr-2 border-2 rounded hover:text-white text-grey border-grey hover:bg-grey"
            >
              Not Done
            </button>
            <button
              @click="remove(todo._id)"
              class="flex-no-shrink p-2 ml-2 border-2 rounded text-red border-red hover:text-white hover:bg-red"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-teal-lightest {
  background-color: #e8fffe;
}
.text-grey-darkest {
  color: #364349;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
}
.hover\:bg-teal:hover {
  background-color: #4dc0b5;
}
.text-teal {
  color: #4dc0b5;
}
.border-teal {
  border-color: #4dc0b5;
}
.hover\:bg-green:hover {
  background-color: #38c172;
}
.text-green {
  color: #38c172;
}
.border-green {
  border-color: #38c172;
}
.hover\:bg-red:hover {
  background-color: #e3342f;
}
.text-red {
  color: #e3342f;
}
.border-red {
  border-color: #e3342f;
}
.hover\:bg-grey:hover {
  background-color: #9babb4;
}
.text-grey {
  color: #9babb4;
}
.border-grey {
  border-color: #9babb4;
}
.flex-no-shrink {
  flex-shrink: 0;
}
.hover\:text-white:hover {
  color: #fff;
}
.hover\:bg-grey:hover {
  background-color: #9babb4;
}
</style>

<script>
export default {
  name: "App",
  data() {
    return {
      todos: [],
      value: "",
      apiHost: "https://api.mmdb.rds.web.id",
    };
  },
  created() {
    this.getTodoList();
  },
  methods: {
    async getTodoList() {
      const fetchTodos = await fetch(`${this.apiHost}/api/get`);
      const todos = await fetchTodos.json();
      this.todos = todos.data;
    },
    async createData() {
      const fetchCreate = await fetch(`${this.apiHost}/api/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: this.value }),
      });
      const todo = await fetchCreate.json();
      this.todos.unshift(todo.data);
      this.value = "";
    },
    async remove(id) {
      await fetch(`${this.apiHost}/api/${id}/remove`, {
        method: "DELETE",
      });
      this.todos = this.todos.filter((todo) => todo._id !== id);
    },
    async setDone(id, done) {
      await fetch(`${this.apiHost}/api/${id}/set-done`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ done: !done }),
      });

      const index = this.todos.findIndex((todo) => todo._id === id);
      if (index > -1) {
        this.todos[index].isDone = !done;
      }
    },
  },
};
</script>
